const baseUrl = process.env.PUBLIC_URL;
const hubUrl = (process.env.NODE_ENV === 'development')? process.env.REACT_APP_HUB_ENDPOINT : `https://${window.location.hostname}`;
const contentSiteUrl = process.env.REACT_APP_CONTENT_BASE;

const urls = {
  baseUrl,
  vimeoPlayer: 'https://player.vimeo.com/video',
  youtubePlayer: 'https://www.youtube.com/embed',

  // API URLs
  hubUrl,
  hubAuthRoot: `${hubUrl}/auth/member`,
  hubUIApiRoot: `${hubUrl}/ui/api`,
  hubAPITopoData: `${hubUrl}/ui/api/content/system/map/topodata`,

  // WP Site URLs
  contentSiteUrl,
  prodContentSiteUrl: 'https://instituteofcatholicculture.net',
  contactUs: `${contentSiteUrl}/contact-us`,
  magdalaContactUs: `${contentSiteUrl}/magdala-contact-us`,
  eventQuestion: `${contentSiteUrl}/form/event-discussion-question/`,
  form990: `${contentSiteUrl}/wp-content/uploads/2025/02/2023-Public-Disclosure-Institute-of-Catholic-Culture.pdf`,

  // Social Media URLs
  shareFacebook: 'https://www.facebook.com/sharer/sharer.php?u=',
  shareTwitter: 'https://twitter.com/share?text=Sharing this program from @InstituteCC&url=',
  socialFacebookICC: 'https://www.facebook.com/Institute-of-Catholic-Culture-174222506218/',
  socialInstagramICC: 'https://www.instagram.com/p/CTw4kUttKjA/',
  socialTwitterICC: 'https://twitter.com/institutecc',
  socialYouTubeICC: 'https://www.youtube.com/user/InstituteCC',

  // App URLs
  root: `${baseUrl}/`,
  notFound: `${baseUrl}/not-found`,
  share: `${baseUrl}/share/:token`,
  filter: `${baseUrl}/filter`,
  page: `${baseUrl}/:slug`,
  registrationLandingPage: `${baseUrl}/register/:slug`,
  article: `${baseUrl}/articles/:slug`,
  privacyPolicy: `${baseUrl}/account/privacy`,
  search: `${baseUrl}/search`,
  curriculum: `${baseUrl}/curriculum`,
  about: `${baseUrl}/about`,
  staff: `${baseUrl}/about/staff`,
  leadership: `${baseUrl}/about/leadership`,
  pilgrimage: `${baseUrl}/pilgrimage-to-the-holy-land`,
  retreat: `${baseUrl}/living-catholic`,
  retreatName: `${baseUrl}/living-catholic`,
  magdala: 'https://magdalaapostolate.org',
  missionPartners: `${baseUrl}/mission-partners`,
  annualReport: `${baseUrl}/2022-report`,
  jobs: `${baseUrl}/employment-opportunities`,
  ambassadors: `${baseUrl}/media-resources`,

  // App URLs - Authentication
  login: `${baseUrl}/login`,
  loginGoogle: `${baseUrl}/login/google`,
  loginFacebook: `${baseUrl}/login/facebook`,
  logout: `${baseUrl}/logout`,
  logoutCompleted: `${baseUrl}/logout/completed`,
  pwdReset: `${baseUrl}/login/reset`,
  pwdResetRequest: `${baseUrl}/login/reset/request`,
  rememberDevice: `${baseUrl}/login/remember`,
  loginActivationPrompt: `${baseUrl}/login/activation`,
  loginRedirect: `${baseUrl}/login/redirect`,

  // App URLs - Classroom
  eventsClassroomBase: `/events/:slug`,
  eventsClassroomVideo: `/events/:slug/video/:videoId`,
  eventsClassroomAudio: `/events/:slug/audio/:audioId`,
  reflectionsLatinClassroomBase: `/reflections/sunday-gospel/:reflectionId`,
  reflectionsLatinClassroomVideo: `/reflections/sunday-gospel/:reflectionId/video/:videoId`,
  reflectionsLatinClassroomAudio: `/reflections/sunday-gospel/:reflectionId/audio/:audioId`,
  reflectionsByzantineClassroomBase: `/reflections/byzantine-gospel/:reflectionId`,
  reflectionsByzantineClassroomVideo: `/reflections/byzantine-gospel/:reflectionId/video/:videoId`,
  reflectionsByzantineClassroomAudio: `/reflections/byzantine-gospel/:reflectionId/audio/:audioId`,

  // App URLs - Events
  event: `${baseUrl}/events/:slug`,
  events: `${baseUrl}/events`,
  eventsPath: '/events',
  eventsBrowse: `${baseUrl}/events/list/browse`,
  eventsLinkedList: `${baseUrl}/events/list/:slug`,
  eventsByCategory: `${baseUrl}/events/categories/:slug`,

  // App URLs - Instructors
  instructor: `${baseUrl}/instructors/:instructorId`,
  instructors: `${baseUrl}/instructors`,

  // App URLs - Courses
  courses: `${baseUrl}/courses`,
  coursesCatechism: `${baseUrl}/courses/catechism`,
  courseApplication: `${baseUrl}/courses/application`,
  course: `${baseUrl}/courses/:slug`,
  courseTopic: `${baseUrl}/courses/:slug/topics/:topicSlug`,
  courseAssessment: `${baseUrl}/courses/:slug/assessments/:assessmentSlug`,

  // External Partner URLs - Courses
  canvasVSI: 'https://veterumsapientia.org/canvas',

  // App URLs - Learn More
  learnMore: `${baseUrl}/learn`,
  learnConfirmation: `${baseUrl}/learn/confirmation`,

  // App URLs - Reflections
  reflectionsLatin: `${baseUrl}/reflections/sunday-gospel`,
  reflectionsByzantine: `${baseUrl}/reflections/byzantine-gospel`,
  reflection: `${baseUrl}/reflections/:slug`,

  // App URLs - Member Registration
  memberActivation: `${baseUrl}/member/activation`,
  memberRegistration: `${baseUrl}/member/registration`,
  memberGoogleRegistration: `${baseUrl}/member/registration/google`,
  memberFacebookRegistration: `${baseUrl}/member/registration/facebook`,

  // App URLs - Member Account/Profile
  accountDeletion: `${baseUrl}/account/deletion`,
  accountDeletionComplete: `${baseUrl}/account/deletion/complete`,
  profile: `${baseUrl}/profile`,
  profileChangePassword: `${baseUrl}/profile/password`,
  profileEmailNotifications: `${baseUrl}/notifications/email`,

  // App URLs - Magdala
  magdalaRegistration: `${baseUrl}/magdala/registration`,
  magdalaLandingPage: `${baseUrl}/magdala`,
  magdalaProgramPage: `${baseUrl}/magdala/program`,
  magdalaCoursesPage: `${baseUrl}/magdala/courses`,
  magdalaCurriculumPage: `${baseUrl}/magdala/curriculum`,

  // App URLs - Donation
  donate: `${baseUrl}/donate`,
  donateFundraiseupStandard: 'https://instituteofcatholicculture.org/?form=donate',
  donateFundraiseupMagdala: 'https://instituteofcatholicculture.org/?form=magdala',
  replyDeviceEOY2024redirectPath: '/endofyear2024',

  // App URLs - Personalized Content/Data
  myDonations: `${baseUrl}/my/donations`,
  myFavorites: `${baseUrl}/my/favorites`,
  myTranscript: `${baseUrl}/my/transcript`,
  myWatched: `${baseUrl}/my/watched`,

  // SUPPORT FOR OLD PRE-MIGRATION URLS
  loginMigrated: `${baseUrl}/member/login`,
  donateMigrated: `${baseUrl}/donate-online`,
  missionMigrated: `${baseUrl}/about/mission`,
  boardMigrated: `${baseUrl}/about/board-leadership`,
  scholarsMigrated: `${baseUrl}/scholars`,
  privacyPolicyMigrated: `${baseUrl}/privacy-policy`,
  libraryMigrated: `${baseUrl}/library`,
  symposiumMigrated: `${baseUrl}/sophia-symposium`,
  talkMigrated: `${baseUrl}/talk/:slug`,
  
  // Paths for Magdala Toolbar
  magdalaProgramPath: '/magdala/program', 
  magdalaCoursesPath: '/magdala/courses',
  magdalaCurriculumPath: '/magdala/curriculum',

  // paths that contain these values remove horizontal/vertical padding within main
  noBoxing: [ 
    { match: '/2022-report', exact: true }
  ],
  
  // paths that contain these values hide header, footer, toolbar
  distractionless: [ 
    { match: '/register/', exact: false } // registration landing pages
  ],

  /*
  paths that historically mapped to legacy donation campaign URLs
  i.e. '/2024pledgerd -> '/donate?campaign=2024pledgerd'
  */
  legacyCampaignRedirects: [
    '/endofyear2023',
    '/christmas2023',
    '/christmasdrive2023',
    '/2024pledge',
    '/donate/2024drive',
  ],

  migrationRedirects: [
    { oldPath: '/about/our-history', newPath: '/our-history' },
    { oldPath: '/about/history', newPath: '/our-history' },
    { oldPath: '/about/endorsements', newPath: '/' },
    { oldPath: '/ewtn', newPath: '/' },
    { oldPath: '/presidentsconference', newPath: '/events/crisis' },
    { oldPath: '/sundaygospelreflections', newPath: `/sunday-gospel` },
    { oldPath: '/byzantine-gospel-reflections', newPath: `/sunday-gospel` },
    { oldPath: '/reflections/byzantine-gospel', newPath: `/sunday-gospel` },
    { oldPath: '/contact-us', newPath: `${contentSiteUrl}/contact-us`, external: true },
    { oldPath: '/student-profile', newPath: '/my/transcript' },
    { oldPath: '/corporate-sponsors', newPath: '/mission-partners' },
    { oldPath: '/2022', newPath: '/2022-report' }
  ],

  magdalaRedirects: [
    { oldPath: '/about/mission', newPath: '/magdala' },
    { oldPath: '/about/scholars', newPath: '/magdala/courses' },
    { oldPath: '/about/communities', newPath: '/magdala' },
    { oldPath: '/curriculum', newPath: '/magdala/courses' },
    { oldPath: '/program/enrollment-requirements', newPath: '/magdala/program' },
    { oldPath: '/program/registration', newPath: '/magdala/registration' },
    { oldPath: '/program/technology-tips', newPath: '/magdala/program' },
    { oldPath: '/student-access', newPath: '/magdala/courses' },
    { oldPath: '/about', newPath: '/magdala' },
    { oldPath: '/program', newPath: '/magdala/program' },
  ],

  courseMigrationRedirects: [
    { oldSlug: 'catechism-101-the-profession-of-faith', newSlug: 'catechism-101-the-profession-of-faith' },
    { oldSlug: 'catechism-102-the-celebration-of-the-christian-mystery', newSlug: 'catechism-102-the-celebration-of-the-christian-mystery' },
    { oldSlug: 'catechism-103-life-in-christ', newSlug: 'catechism-103-life-in-christ' },
    { oldSlug: 'catechism-104-christian-prayer', newSlug: 'catechism-104-christian-prayer' },
    { oldSlug: 'catechism-201-audit', newSlug: 'catechism-201-2' },
    { oldSlug: 'catechism-201-certificate', newSlug: 'catechism-201-2' },
    { oldSlug: 'history-101-audit', newSlug: 'history-101' },
    { oldSlug: 'history-101-certificate', newSlug: 'history-101' },
    { oldSlug: 'history-101-certificate-self-paced', newSlug: 'history-101' },
    { oldSlug: 'history-102-audit', newSlug: 'history-102' },
    { oldSlug: 'history-102-certificate', newSlug: 'history-102' },
    { oldSlug: 'history-102-self-paced-certificate', newSlug: 'history-102' },
    { oldSlug: 'philosophy-101-audit', newSlug: 'philosophy-101' },
    { oldSlug: 'philosophy-101-certificate', newSlug: 'philosophy-101' },
    { oldSlug: 'scripture-101-introduction-to-the-old-testament', newSlug: 'scripture-101' },
    { oldSlug: 'scripture-102-audit', newSlug: 'scripture-102' },
    { oldSlug: 'scripture-102-introduction-to-the-new-testament', newSlug: 'scripture-102' },
    { oldSlug: 'scripture-402-audit', newSlug: 'the-third-heaven' },
    { oldSlug: 'scripture-402-certificate', newSlug: 'the-third-heaven' }
  ]
};

export default urls;
